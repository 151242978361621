<template>
	<div class="video-components">
		<div style="">
			<div style="display: flex;">
				<div style="flex:1;display: flex; flex-direction: column; background: #f4f4f4; border-radius: 10px; padding: 10px;  box-sizing: border-box">
					<p>原视频</p>
					<video ref="videoRef" class="video" :src="video" controls @loadedmetadata="loadedmetadata" style="margin-right: 20px"/>
				</div>
				<div v-if="videoEnd" style="flex:1;margin-left: 10px;display: flex; flex-direction: column; background: #f4f4f4; border-radius: 10px;  padding: 10px; box-sizing: border-box">
					<p>截取预览</p>
					<video  ref="videoEndRef" class="video" :src="videoEnd" controls/>
				</div>
			</div>
			<p>处理信息：{{ message }}</p>
		</div>
		<div class="video-tools">

			<div class="tips">视频位置：{{videoCurrentTime}} 秒(点击帧图片获取)</div>
			<div class="video-img">
				<img v-for="item of frames" :key="item" :src="item.img" alt="" @click="selectTime(item)">
			</div>
			<div>
				<p>
					开始时间:<input type="number" v-model="startTime" placeholder="开始时间" @focus="focusInput=1">
				</p>
				<p>
					结束时间:<input type="number" v-model="endTime" placeholder="结束时间" @focus="focusInput=2">
				</p>
				<p style="font-size: 12px; color: palevioletred">
					截取视频：<span>光标定位到开始或结束输入框点击视频帧图片或手动输入</span>
				</p>
				<p>
					<el-button class="save" @click="cutVideo">开始截取</el-button>
					<el-button v-if="videoEnd" class="save" :loading="updateStatus" style="margin-left: 20px; background: #044897" @click="saveVideo">保存</el-button>
<!--					<el-button class="mark" @click="addMark">添加遮罩</el-button>-->
				</p>
			</div>

		</div>
	</div>
</template>

<script setup>
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'
import {nextTick, onMounted, onUnmounted, ref} from 'vue'
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {saveImg} from "@/api/imgEditor";

const videoURL = ref('')
const ffmpeg = new FFmpeg()
const message = ref('Click Start to Transcode')
let video = ref('')
let videoEnd = ref('')
const router = useRouter()
const videoRef = ref()
const videoEndRef = ref()
const frames = ref([])
const frameInterval = ref(2)
let captureId = null;
const videoCurrentTime = ref(0)
const startTime = ref(0)
const endTime = ref(0)
const focusInput = ref(1)
const twid = ref()
const updateStatus = ref(false)
const oldVideoSrc = ref('')


onMounted(()=>{
	// 获取路由参数
	console.log('router query:', router.currentRoute.value.query)
	const query = router.currentRoute.value.query
	if(query.src && query.twid){
		oldVideoSrc.value = query.src
		videoURL.value = query.src.indexOf('/uploads/') !== -1? '/uploads/' + query.src.split('/uploads/')[1] : query.src
		twid.value = query.twid
		transcode()
	}
	if (query.cookie) {
		document.cookie = `PHPSESSID=${query.cookie}`
	}
})

// const addMark = ()=>{
// 	ffmpeg.exec([
// 		'-i',
// 		'test.mp4',
// 		'-vf',
// 		"drawtext:text='ffmpeg.wasm':x=10:y=10:fontsize=24:fontcolor=white",
// 		'output1.mp4'
// 	]).then(async ()=>{
// 		const data = await ffmpeg.readFile('output1.mp4')
// 		videoEnd.value = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }))
// 		message.value = '截取完成'
// 		console.log('添加遮罩成功')
// 	}).catch((e)=>{
// 		console.log('添加遮罩失败', e)
// 	})
// }

const cutVideo = async ()=>{
	if(videoRef.value){
		if(startTime.value>endTime.value){
			alert('开始时间不能大于结束时间')
			return
		}
		if(endTime.value===0){
			alert('请选择截取结束时间')
			return
		}
		const cutCode = ['-i',  'test.mp4' , '-ss', formatTime(startTime.value), '-t', `${formatTime(endTime.value- startTime.value)}`,'-c','copy', 'output.mp4']
		console.log(cutCode)
		ffmpeg.exec(cutCode)
		.then(async ()=>{
			const data = await ffmpeg.readFile('output.mp4')
			videoEnd.value = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }))
			message.value = '截取完成'
		}).catch((e)=>{
			console.log('截取视频失败', e)
			message.value = '截取视频失败'
		})
	}
}

const saveVideo = async ()=>{
	if(!videoEnd.value){
		alert('请先截取视频或编辑视频')
		return
	}
	try{
		if(updateStatus.value) return
		updateStatus.value = true
		// 上传文件
		const file = await ffmpeg.readFile('output.mp4')
		const savePost = await saveImg({
			file: file,
			type:1,
			img:oldVideoSrc.value
		}, twid.value)
		console.log(savePost)
		if (savePost.status === '00') {
			oldVideoSrc.value = savePost.data.url
			ElMessage({
				message: '保存成功',
				type: 'success'
			})
		} else {
			ElMessage({
				message: savePost.msg || '保存失败',
				type: 'error'
			})
		}
		console.log(savePost)
		updateStatus.value = false
	}catch (e) {
		updateStatus.value = false
	}
}

async function transcode() {
	message.value = '加载 ffmpeg-core.js'
	ffmpeg.on('log', ({ message: msg }) => {
		message.value = msg
	})
	await ffmpeg.load({
		coreURL: await toBlobURL(`./ffmpeg-core.js`, 'text/javascript'),
		wasmURL: await toBlobURL(`./ffmpeg-core.wasm`, 'application/wasm'),
		workerURL: await toBlobURL(`./ffmpeg-core.worker.js`, 'text/javascript')
	})
	message.value = '转换视频为本地视频流'
	await ffmpeg.writeFile('test.mp4', await fetchFile(videoURL.value))
	await ffmpeg.exec(['-i', 'test.mp4', 'test.mp4'])
	message.value = '转换完成，可播放'
	const data = await ffmpeg.readFile('test.mp4')
	video.value = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }))
	console.log('video ready')
}

const loadedmetadata = ()=>{
	nextTick(async ()=>{
		if (!videoRef.value) return;
		frames.value = []
		// 初始化捕获过程
		const videoLong = videoRef.value.duration
		if(videoLong<60){
			frameInterval.value = 1
		}else{
			// 超过60秒的视频，最多截取60张图片
			frameInterval.value = Math.floor(videoLong/60)
		}
		endTime.value = videoRef.value.duration
		videoRef.value.currentTime = 0; // 开始时间
		captureId = requestAnimationFrame(captureFrame);
	})
}
const captureFrame = () => {
	if (!videoRef.value || videoRef.value.currentTime >= videoRef.value.duration) {
		// 停止捕获
		cancelAnimationFrame(captureId);
		captureId = null;
		return;
	}
	// 绘制帧到canvas并转换为Data URL
	const canvas = document.createElement('canvas');
	canvas.width = videoRef.value.videoWidth;
	canvas.height = videoRef.value.videoHeight;
	const ctx = canvas.getContext('2d');
	ctx.drawImage(videoRef.value, 0, 0, canvas.width, canvas.height);
	frames.value.push({
		img:canvas.toDataURL('image/png'),
		time: videoRef.value.currentTime
	});
	// 递增currentTime并请求下一帧
	videoRef.value.currentTime += frameInterval.value;
	captureId = requestAnimationFrame(captureFrame);
};

const selectTime = (item)=>{
	videoCurrentTime.value = item.time
	if(focusInput.value===1){
		startTime.value = item.time
	}else{
		endTime.value = item.time
	}
}
// 秒转换为 时:分:秒, 并补零
const formatTime = (time) => {
	const hours = Math.floor(time / 3600);
	const minutes = Math.floor((time % 3600) / 60);
	const seconds = Math.floor(time % 60);
	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

onUnmounted(() => {
	// 清理资源
	if (captureId) {
		cancelAnimationFrame(captureId);
	}
});
</script>

<style>
div{
	margin: 0;
	padding: 0;
}
.video-components{
	display: flex;
	flex-direction: column;
	padding: 10px;
}
.video{
	max-height: 400px;
	max-width: 100%;
	box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
.video-tools{
	flex: 1;
	background: rgba(102, 102, 102, 0.15);
	margin-top: 20px;
	border-radius: 10px;
	padding: 10px;
	box-sizing: border-box;
	input{
		height: 30px;
		padding-left: 10px;
		margin-left: 4px;
		border-radius: 4px;
	}
}
.video-img{
	height: 50px;
	display: inline-block;
	border: 1px solid #666;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	img{
		height: 50px;
	}
	.tips{
		position: absolute;
		font-size: 14px;
		margin-bottom: 5px;
	}

}
.save,.mark{
	width: 100px;
	height: 40px;
	border: none;
	background: #42b983;
	color: #fff;
}
</style>