<template>
  <router-view/>
</template>

<style lang="scss">

</style>

<script>
// $('.filelist li img').on('click',function(){
// 	const type = '{$info['date_type']}'
// 	if(type == 1){ // 视频
// 		const src = "{$info.videos}"
// 		const twid = "{$info.id}"
// 		const cookie = getCookie('PHPSESSID')
// 		console.log(src, twid,cookie,  $.param({src,twid,cookie}))
// 		const query = $.param({src,twid,cookie})
// 		window.open('https://edit.webtest.vip/#/videoEdit?' + query)
// 	}
// 	if(type == 2){ // 图片
// 		const src = $(this).attr('src')
// 		const twid = "{$info.id}"
// 		const cookie = getCookie('PHPSESSID')
// 		console.log(src, twid,cookie,  $.param({src,twid,cookie}))
// 		const query = $.param({src,twid,cookie})
// 		window.open('https://edit.webtest.vip/#/imgEdit?' + query)
// 	}
// })
</script>
