<template>
 <FFmpegDemo />
</template>

<script>
import FFmpegDemo from "@/components/FFmpegDemo.vue";
export default {
  name: 'HomeView',
	components:{FFmpegDemo},
}
</script>
