import {createRouter, createWebHashHistory} from 'vue-router'
import VideoEdit from '../views/videoEdit.vue'
import ImgEdit from "@/views/imgEdit.vue";

const routes = [
  {
    path: '/videoEdit',
    name: 'videoEdit',
    component: VideoEdit
  },
  {
    path: '/imgEdit',
    name: 'ImgEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ImgEdit
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
