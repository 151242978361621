import http from "@/utils/http";

export const saveImg = (data, twid) => {
    return http({
        url: `/admin/CuttingTools/upload?twitter_base_id=${twid}`,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data
    })
}