<script setup>
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from "tui-image-editor";
import {saveImg} from "@/api/imgEditor";
import {onMounted, ref} from "vue";
import router from "@/router";
import {ElMessage} from "element-plus";

// 中文菜单
const locale_zh = {
	ZoomIn: "放大",
	ZoomOut: "缩小",
	Hand: "手掌",
	History: "历史",
	Resize: "调整宽高",
	Crop: "裁剪",
	DeleteAll: "全部删除",
	Delete: "删除",
	Undo: "撤销",
	Redo: "反撤销",
	Reset: "重置",
	Flip: "镜像",
	Rotate: "旋转",
	Draw: "画",
	Shape: "形状标注",
	Icon: "图标标注",
	Text: "文字标注",
	Mask: "遮罩",
	Filter: "滤镜",
	Bold: "加粗",
	Italic: "斜体",
	Underline: "下划线",
	Left: "左对齐",
	Center: "居中",
	Right: "右对齐",
	Color: "颜色",
	"Text size": "字体大小",
	Custom: "自定义",
	Square: "正方形",
	Apply: "应用",
	Cancel: "取消",
	"Flip X": "X 轴",
	"Flip Y": "Y 轴",
	Range: "区间",
	Stroke: "描边",
	Fill: "填充",
	Circle: "圆",
	Triangle: "三角",
	Rectangle: "矩形",
	Free: "曲线",
	Straight: "直线",
	Arrow: "箭头",
	"Arrow-2": "箭头2",
	"Arrow-3": "箭头3",
	"Star-1": "星星1",
	"Star-2": "星星2",
	Polygon: "多边形",
	Location: "定位",
	Heart: "心形",
	Bubble: "气泡",
	"Custom icon": "自定义图标",
	"Load Mask Image": "加载蒙层图片",
	Grayscale: "灰度",
	Blur: "模糊",
	Sharpen: "锐化",
	Emboss: "浮雕",
	"Remove White": "除去白色",
	Distance: "距离",
	Brightness: "亮度",
	Noise: "噪音",
	"Color Filter": "彩色滤镜",
	Sepia: "棕色",
	Sepia2: "棕色2",
	Invert: "负片",
	Pixelate: "像素化",
	Threshold: "阈值",
	Tint: "色调",
	Multiply: "正片叠底",
	Blend: "混合色",
	Width: "宽度",
	Height: "高度",
	"Lock Aspect Ratio": "锁定宽高比例",
};

const twid = ref('')
const bolb = ref()
const updateStatus = ref(false)
const imageEditor = ref()
const oldImgSrc = ref('')

onMounted(()=>{
	//获取路由参数
	console.log(router)
	const query = router.currentRoute.value.query
	console.log('page query', query)
	if (query.src && query.twid) {
		twid.value = query.twid
		oldImgSrc.value = query.src
		const isUploadImg = query.src.indexOf('/uploads/') !== -1? '/uploads/' + query.src.split('/uploads/')[1] : query.src
		imageEditor.value = new ImageEditor(
				document.querySelector("#tui-image-editor"),
				{
					includeUI: {
						loadImage: {
							path: isUploadImg,
							name: "image",
						},
						//menu: ["draw", "shape", "text"], // 底部菜单按钮列表 隐藏镜像flip和遮罩mask
						//initMenu: "draw", // 默认打开的菜单项
						menuBarPosition: "left", // 菜单所在的位置
						locale: locale_zh, // 本地化语言为中文
					},
				}
		);
	}else{
		ElMessage({
			message: '参数错误',
			type: 'error'
		})
	}
	if (query.cookie) {
		document.cookie = `PHPSESSID=${query.cookie}`
	}
})


const save = async ()=>{
	try {
		if (updateStatus.value) return
		updateStatus.value = true
		const data = imageEditor.value.toDataURL({
			format: "png",
			quality: 1,
			// outputSize: 0.1,
			// outputType: "jpeg",
		})
		const blob = base64toBlob(data, 'image/png')
		bolb.value = URL.createObjectURL(blob)
		console.log(bolb.value)
		// 上传文件
		const savePost = await saveImg({
			file: blob,
			type:2,
			img: oldImgSrc.value
		}, twid.value)
		console.log(savePost)
		if (savePost.status === '00') {
			oldImgSrc.value = savePost.data.url
			ElMessage({
				message: '保存成功',
				type: 'success'
			})
		} else {
			ElMessage({
				message: savePost.msg || '保存失败,可能授权已过期',
				type: 'error'
			})
		}
		console.log(savePost)
		updateStatus.value = false
	} catch (e) {
		console.log(e)
		updateStatus.value = false
	}
}

const base64toBlob = (base64Data, contentType)=> {
	contentType = contentType || '';
	const sliceSize = 1024;
	const byteCharacters = atob(base64Data.split(',')[1]);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	return new Blob(byteArrays, {type: contentType});
}
</script>

<template>
	<div class="img-edit">
		<el-button :loading="updateStatus" class="btn" round type="success" @click="save">保 存</el-button>
		<div id="tui-image-editor"></div>
		<!--		<img :src="bolb" alt="" style="position: fixed; top:0; z-index: 2; width: 100px">-->
	</div>
</template>

<style>
body, html {
	margin: 0;
	padding: 0;
}

.img-edit {
	height: 100vh;
}

.tui-image-editor-header-buttons {
	display: none;
}

.btn {
	position: fixed !important;
	top: 20px;
	right: 20px;
	z-index: 1;
	width: 100px;
	border-radius: 20px;
	background: darkorange;
	color: #fff;
	font-weight: bold;
	border: none;
	cursor: pointer;
}
.tui-image-editor-header-logo{
	display: none;
}
</style>
